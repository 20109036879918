import styles from './trainer.module.css';

import { useState } from 'react';

import ReactDOM from 'react-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';
import SideMenu from '../components/SideMenu';
import SocialMenu from '../components/SocialMenu';
import BackToTop from '../components/UI/BackToTop';
import backToTopArrow from '../assets/images/back_to_top.svg';
import StandardImageList from '../components/UI/StandardImageList';
import ImageModal from '../components/UI/ImageModal';
import Backdrop from '../components/UI/Backdrop';
import bitmap3 from '../assets/images/bitmap_trainer_retouched.png';
import separator from '../assets/images/article_separator.svg';
import { textAlign } from '@mui/system';


const Trainer = (props) => {

  const backdrop = document.getElementById('backdrop');

  const imageViewer = document.getElementById('imageViewer');

  const [modalIsOn, setModalIsOn] = useState(false);
  const [displayedImageInModal, setDisplayedImageInModal] = useState(null);
  const [activePage, setActivePage] = useState('link2');
  const [expandableBlock1Expanded, setExpandableBlock1Expanded] = useState(false);
  const [expandableBlock2Expanded, setExpandableBlock2Expanded] = useState(false);

  const checkActivePage = () => {
    const contentMain = document.querySelector('main');
    const anchor1 = document.querySelector('.article1').getBoundingClientRect();
    const anchor2 = document.querySelector('.article2').getBoundingClientRect();
    const anchor3 = document.querySelector('.article3').getBoundingClientRect();
    const anchor4 = document.querySelector('.article4').getBoundingClientRect();

    const anchorTop = props.viewportHeight > 430 ? 260 : 161;

    if (anchor4.top <= anchorTop + 100) {
      setActivePage('link5')
    } else if (anchor3.top <= anchorTop) {
      setActivePage('link4')
    } else if (anchor2.top <= anchorTop) {
      setActivePage('link3')
    } else if (anchor1.top <= anchorTop) {
      setActivePage('link2');
    } else setActivePage('');
  }

  const imageClickHandler = (image) => {
    setModalIsOn(true);
    setDisplayedImageInModal(image);
  }

  const onClickOnX = () => {
    setModalIsOn(false);
  }

  const imageChangeHandler = (image) => {
    setDisplayedImageInModal(image);
  }

  const itemData = [
    {
      img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
      title: 'Breakfast',
    },
    {
      img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
      title: 'Burger',
    },
    {
      img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
      title: 'Camera',
    },
    {
      img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
      title: 'Coffee',
    },
    {
      img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
      title: 'Hats',
    },
    {
      img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
      title: 'Honey',
    },
    {
      img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
      title: 'Basketball',
    },
    {
      img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
      title: 'Fern',
    },
    {
      img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
      title: 'Mushrooms',
    },
    {
      img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
      title: 'Tomato basil',
    },
    {
      img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
      title: 'Sea star',
    },
    {
      img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
      title: 'Bike',
    },
  ];


  const onGoToArticle1 = () => {
    const contentMain = document.querySelector('main');
    const anchor1 = document.querySelector('.article1');
    const anchorTop = props.viewportHeight > 430 ? 260 : 159;

    const additionalOffset = 20;

    let a = contentMain.scrollTop + anchor1.getBoundingClientRect().top - anchorTop + additionalOffset;
    contentMain.scrollTo({
      top: a,
      left: 0,
      behavior: 'smooth'
    });
  }

  const onGoToArticle2 = () => {

    const contentMain = document.querySelector('main');
    const anchor2 = document.querySelector('.article2');
    const anchorTop = props.viewportHeight > 430 ? 260 : 159;

    const additionalOffset = 20;

    let b = contentMain.scrollTop + anchor2.getBoundingClientRect().top - anchorTop + additionalOffset;
    contentMain.scrollTo({
      top: b,
      left: 0,
      behavior: 'smooth'
    });
  }

  const onGoToArticle3 = () => {

    const contentMain = document.querySelector('main');
    const anchor3 = document.querySelector('.article3');

    const anchorTop = props.viewportHeight > 430 ? (props.viewportHeight > 1366 ? 260 : 600 ) : 161;

    const additionalOffset = 20;

    let c = contentMain.scrollTop + anchor3.getBoundingClientRect().top - anchorTop + additionalOffset;
    contentMain.scrollTo({
      top: c,
      left: 0,
      behavior: 'smooth'
    });
  }



  const onGoToArticle4 = () => {
    const contentMain = document.querySelector('main');
    const anchor4 = document.querySelector('.article4');

    const anchorTop = props.viewportHeight > 430 ? 260 : 159;

    // Offset für das letzte Menupunkt damit der Cursor springt
    const additionalOffset = 20;

    let d = contentMain.scrollTop + anchor4.getBoundingClientRect().top - anchorTop + additionalOffset;
    contentMain.scrollTo({
      top: d,
      left: 0,
      behavior: 'smooth'
    });
  }

  const onBackToTopHandler = () => {

    const contentMain = document.querySelector('main');
    contentMain.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  const onContent1ExpandHandler = () => {
    setExpandableBlock1Expanded(!expandableBlock1Expanded);

  }
  const onContent2ExpandHandler = () => {
    setExpandableBlock2Expanded(!expandableBlock2Expanded);

  }


  return (
    <main className={styles.main} onScroll={checkActivePage}>

      {props.viewport > 430 &&
        <SideMenu
          link1='About'
          link2='Workshops'
          link3='Klienten'
          link4='Media'
          onLink1Click={onGoToArticle1}
          onLink2Click={onGoToArticle2}
          onLink3Click={onGoToArticle3}
          onLink4Click={onGoToArticle4}
          isActive={activePage}
          viewport={props.viewport}
          viewportHeight={props.viewportHeight}
        />}

      {(props.viewport > 430 && props.viewportHeight > 430) && <SocialMenu />}

      <Header
        viewport={props.viewport}
        viewportHeight={props.viewportHeight}
        color={props.viewport > 430 ? 'rgba(208,110,110,1)' : 'black'}>
        Trainer
      </Header>
      <img src={bitmap3} alt="Maik Goth, trainer" className={styles.picture} />

      <section className={styles.content}>
        <article className='article1'>
          <h3 className={styles.articleTitle} style={{ marginBottom: '60px' }}>Über mich als <span className={styles.titleShift}>TRAINER</span></h3>
          <div className={styles.articleContent}>
            <div>
              <p style={{ textAlign: 'center' }}><strong>Neue Generationen von Akademiker:innen stärken und zentrale Kompetenzen vermitteln</strong></p>
              <p style={{ textAlign: 'center' }}><em>interdisziplinär — didaktisch — pragmatisch — leidenschaftlich — weltoffen — inklusiv — exzellent
              </em></p>

              <p>Herzlich Willkommen!</p>

              <p>Ich unterrichte Workshops für MA-Studierende, Doktorand:innen und Postdocs in akademischem Englisch, in Forschungs- und Selbstmanagement (inkl. psychische Gesundheit) sowie in Kommunikation und Präsentation. Zu meinen Auftraggebern zählen Universitäten, Forschungsinstitute und Graduiertenakademien. Meine Workshops gründen sich auf umfassende Erfahrungen in allen Bereichen der Akademie und sind auf die persönlichen Bedürfnisse und professionellen Anforderungen von (Nachwuchs-)Wissen- schaftler:innen zugeschnitten.</p>
              <p>Als international tätiger Akademiker habe ich über zwanzig Jahre auf Konferenzen, als Autor akademischer Monographien, Artikel und Rezensionen, als Herausgeber, Peer-Reviewer und Universitätsdozent verbracht. Dort habe ich auch meine Leidenschaft für den internationalen und interdisziplinären Austausch, für das Zusammenbringen von Menschen aller Länder und (akademischer) Kulturen entdeckt. Seither verquicke ich akademische Expertise mit der Freude am Unterrichten, um ein einladendes, offenes soziales Miteinander zu gestalten, in dem die Teilnehmer:innen ihre Komfortzonen verlassen und sich auf Neues einlassen können. </p>
              <p>Als Wissenschaftler mit persönlicher Geschichte mache ich psychische Gesundheit (Stichwort »Mental Health«) zu einem zentralen Anliegen in der Universität und der akademischen Laufbahn. Daraus entstanden beliebte Workshops und meine Arbeit als Mental Health Advocate (»Fürsprecher für psychische Gesundheit«), der ich auch auf themenbezogenen Symposien und Veranstaltungen nachgehe.</p>
              <p>Im Oktober 2022 habe ich mit dem Biologie-Professor Hendrik Huthoff (Berlin) das selbstgeschriebene und selbstproduzierte Lied »Rock Your Mental Health« veröffentlicht, um auf dieses Thema aufmerksam zu machen und <a href="https://www.youtube.com/watch?v=V-Ou4K_WTe4" target="blank" style={{ textDecoration: "underline" }}>Lösungswege aufzuzeigen</a>. Der Erlös ging an die Organisation <a href="https://www.irrsinnig-menschlich.de" target="blank" style={{ textDecoration: "underline" }}><em>Irrsinnig Menschlich.</em></a></p>
              <p><strong>Let's get in touch!</strong></p>
            </div>
          </div>
          <BackToTop onBackToTop={onBackToTopHandler} />
        </article>

        <img src={separator} alt="separator line" className={styles.articleSeparator} />

        <article className='article2'>

          <h3 className={styles.articleTitleCenter}>WORKSHOPS</h3>
          <div className={styles.articleContent}>

            <div className={styles.expandableTitle} onClick={onContent1ExpandHandler}><img src={backToTopArrow} className={expandableBlock1Expanded ? styles.arrowSmaller180g : styles.arrowSmaller90g} /> <h4>ENGLISCHE WISSENSCHAFTSSPRACHE, PRÄSENTATION UND KOMMUNIKATION</h4>
            </div>
            <div className={expandableBlock1Expanded ? styles.expandableContent1Expanded :
              styles.expandableContent1}>

              <p><strong>WORKSHOPS</strong></p>
              <ul>
                <li>
                  <strong>Go International! Academic Writing in English</strong>
                  <em> (Akademisches Schreiben in englischer Sprache)</em>
                </li>
                <li>
                  <strong>Make It Happen! Writing Grant Proposals</strong>
                  <em> (Drittmittelanträge schreiben)</em>
                </li>
                <li>
                  <strong>Share Your Ideas! Planning and Conceptualising Academic Presentations</strong>
                </li>
                <li>
                  <strong>The Floor Is Yours: Successful Academic Presentations in English</strong>
                  <em> (Erfolgreiche akademische Vorträge in englischer Sprache)</em>
                </li>
                <li>
                  <strong>Powers of Persuasion: Presenting, Discussing and Arguing in the Academic Arena</strong>
                  <em> (Die Kraft der Überzeugung: Präsentieren, Diskutieren und Argumentieren in der Akademie)</em>
                </li>
                <li>
                  <strong>Sailing Against the Wind: Communication Strategies for (Early-Career) Researchers</strong>
                  <em> (Segeln gegen den Wind: Kommunikationsstrategien für (Nachwuchs-)Wissenschaft-ler:innen)</em>
                </li>
              </ul>

              <p><strong>LEITBILD</strong></p>
              <p>
                Eine zentrale Anforderung, die die Akademie in Zeiten der Internationalisierung des Forschungs- und Lehrbetriebs an Nachwuchswissenschaftler:innen stellt, ist das Beherrschen der englischen Wissenschaftssprache in Wort und Schrift. Umfassende Kenntnisse in der Fremdsprache machen Akademiker für universitäre Stellen im In- und Ausland attraktiv, ermöglichen ihnen das Forschen in internationalen Netzwerken, verleihen ihnen Kompetenz beim Stellen von Drittmittelanträgen sowie beim Publizieren von wissenschaftlichen Beiträgen in englischer Sprache. So werden sie sicher im universitären Unterricht und im akademischen Miteinander auf internationaler Ebene.
              </p>
              <p>
                Und das Gute ist: Akademisches Englisch ist keine Muttersprache. Als Teil des wissenschaftlichen Handwerkszeugs lässt es sich trainieren und meistern. Hier setzen meine Workshops an, die Teilnehmer:innen als wissbegierige, lernbereite und ambitionierte Akademiker:innen wahrnehmen.
              </p>
              <p>
                Sie unterstützen sie dabei in der internationalen wissenschaftlichen Community sichtbar zu werden;
                international zu publizieren und zu arbeiten;
                ihre Wissenschaftspersönlichkeit in der Fremdsprache darzustellen;
                ihr Bewerbungsportfolio für Stellen und Stipendien attraktiv zu gestalten;
                international zu netzwerken, Partnerschaften einzugehen, Projekte auszuführen und Kurse zu geben.
              </p>
              <p><strong>ZIELE</strong></p>
              <ol>
                <li>

                <ul>
                <li>Vermitteln von Schlüsselkenntnissen der englischen Wissenschaftssprache</li>
                <li>praktischen Tipps und Tricks beim Formulieren in Wort und Schrift</li>
                </ul>
                </li>
                <li>
                <ul>
                  <li>Stärken von Selbstbewusstsein im Umgang mit der Fremdsprache</li>
                <li>Selbständigkeit im Nutzen der englischen Wissenschaftssprache</li>
                <li>Vertrauen in die wissenschaftliche Sprachkompetenz</li>
                </ul>
                </li>
                <li>
                <ul>
                <li>Bewusstmachen der Unterschiede zwischen den Wissenschaftssprachen</li>
                <li>des Stilideals des akademischen Englisch</li>
                <li>der sprachlichen Codes in unterschiedlichen Kontexten</li>
                </ul>
                </li>
                <li>
                <ul>
                <li>Motivieren zum Anwenden der Fremdsprache im akademischen Diskurs</li>
                <li>zum interdisziplinären Arbeiten mit internationalen Kollegen</li>
                </ul>
                </li>
                <li>
                <ul>
                <li>Sensibilisieren für die Besonderheiten und Stärken der englischen Sprache</li>
                <li>den Nutzen der Zweisprachlichkeit im wissenschaftlichen Diskurs</li>
                </ul>
                </li>
                <li>
                <ul>
                <li>Perspektiven bieten für das eigene internationale Wissenschaftsprofil</li>
                <li>die weitere Entwicklung der Sprachkompetenz</li>
                <li>neue wissenschaftliche Ansätze</li>
                </ul>
                </li>
              </ol>
            </div>


            <div className={styles.expandableTitle} onClick={onContent2ExpandHandler}><img src={backToTopArrow} className={expandableBlock2Expanded ? styles.arrowSmaller180g : styles.arrowSmaller90g} />
              <h4>FORSCHUNG UND SELBSTMANAGEMENT, MENTAL HEALTH</h4>
            </div>
            <div className={expandableBlock2Expanded ? styles.expandableContent2Expanded :
              styles.expandableContent2}>
              <strong>WORKSHOPS</strong>
              <p><em>Bold, Passionate and Mindful: Time and Self-Management for Researchers</em><br />
                (Forsch, wissenschaftlich, achtsam: Zeit- und Selbst-Management für Forschende)</p>
                <p><em>Bold, Passionate and Mindful: Make an Impact! Motivation and Mental Health for (Early-Career) Researchers</em><br />
                (Mach den Unterschied: Motivation und Mental Health für (Nachwuchs-)Wissenschaftler:innen)</p>
              <p><em>Don't Let It Drive You Nuts! Resilience and Stress Management for Researchers</em><br />
                (Lass Dich nicht verrückt machen! Resilienz und Selbst-Management bzw. Mental Health für Forschende)</p>
              <p><em>Keep on Rocking: Mental Health for PhD Candidates and Postdocs</em><br />
                (Keep on Rocking: Psychische Gesundheit für Doktorand:innen und Postdocs)</p>
                <p><em>Don't Let This Weigh You Down: How to Deal with Mental Load and Empower Yourself</em><br />
                (Lass Dich nicht unterkriegen: Wie man mit Mental Load umgeht und sich stark macht)</p>
              <p><em>Managing (with) Change: The Many Lives of the Academic</em><br />
                (Handeln im Wandel: Die vielen Leben der Akademiker:innen)</p>

              <strong>LEITBILD</strong>
              <p>Promovierende eint die Leidenschaft zu forschen und Wissen zu schaffen. Sie sind Hochleister:innen, die mit frischen Ansätzen neue Horizonte erschließen und zur Weiterentwicklung ihrer Fächer beitragen. Somit sind sie im engeren Sinne die Zukunft der Wissenschaft und des Wissenschaftsbetriebes. Im weiteren Sinne jedoch sind sie es, die durch ihre Offenheit für Neues, für Veränderung und für Herausforderungen innerhalb und außerhalb der Akademie gesellschaftlichen und ideengeschichtlichen Wandel herbeiführen können.</p>
              <p>Mein Grundsatz ist es, alle Teilnehmer:innen als mündige Nachwuchswissenschaftler:innen, als starke Persönlichkeiten wahrzunehmen und zu beraten. Wegen ihrer Diversität gibt es kein vorgefertigtes Programm, mit dem man Promovierende 'auf eine Linie' bringen kann. Es würde sie nur ihrer Stärken berauben. Um dieser Individualität Rechnung zu tragen, gestalte ich meine Workshops interaktiv. Im Zentrum dieser Veranstaltungen stehen neben der Vermittlung aller Aspekte des Promovierens vor allem Selbstreflexion und pragmatische Überlegungen, die in didaktisch aufbereiteten Workshopeinheiten und Übungen vertieft werden.</p>

              <strong>ZIELE</strong>
              <ul>
                <li>Stärken von Selbständigkeit und Selbstbewusstsein</li>
                
                <li>Vertrauen in die eigenen Denkansätze und akademischen Skills</li>
                <li>kritischer Reflexion zu Theorie und Praxis der Wissenschaft</li>
             

              <li>Bewusstmachen der eigenen Ziele, der eigenen Persönlichkeit, des eigenen Forschungsprofils.</li>
              <li>Motivieren zur Partizipation am Lehrstuhl, im Kolloquium, in der wissenschaftlichen Community, zum interdisziplinären Arbeiten, zur Verbesserung der eigenen Kritikfähigkeit.</li>
              <li>Sensibilisieren für Schlüsselqualifikationen, Wissenstransfer, Interdisziplinarität, Selbstreflexion, Pragmatismus, Pluralismus der Rollen.</li>
              </ul>
              <strong>PERSPEKTIVEN BIETEN</strong>
              <ul>
                <li>Für die Verbindung von Forschung und Lebenspraxis (Stichwort: mental health)</li>
                <li>Für die Möglichkeit, im (persönlichen, beruflichen) Wandel zu bestehen (Stichwort: change management)</li>
              </ul>

              {/* <p>Eine Gesamtliste der Kurse und deren Beschreibungen finden Sie hier [PDF].</p> */}
              {/* <p>Mein vollständiges Leitbild finden sie hier [PDF].</p> */}
            </div>
          </div>
          <BackToTop onBackToTop={onBackToTopHandler} />

        </article>

        <img src={separator} alt="separator line" className={styles.articleSeparator} />

        <article className='article3'>
          <h3 className={styles.articleTitleCenter}>KLIENTEN</h3>
          <div className={styles.articleContent}>
            <ul>
              <li>Friedrich-Schiller-Universität Jena</li>
              <li>Wilhelm-Eberhards-Universität Tübingen</li>
              <li>Brandenburgisch-Technische Universität Cottbus-Senftenberg</li>
              <li>Universität Erfurt</li>
              <li>Universität Leipzig</li>
              <li>Technische Universität Dortmund</li>
              <li>{`10th Young Environmental Scientist Meeting of the Society of Environmental Toxicology and Chemistry (SETAC)`}</li>
              <li>{`German Centre for Integrative Biodiversity Research Halle-Jena-Leipzig  (iDiv) `}</li>
              <li>Helmholtz Institute for Environmental Research, Leipzig</li>
            </ul>
            <p><strong>MENTAL HEALTH EVENTS</strong></p>
            <ul>
              <li>"Surviving in Academia", Leipzig University, 2019</li>
              <li>"Week for Junior Academics", Paderborn University, 2022</li>
              <li>"Mental Health in Academia — An Evening of Art, Science and Personal Testimonials", Jena University, 2022</li>
              <li>"Body, Mind and Soul", Nortal Wellbeing, 2022</li>
            </ul>

          </div>
          <BackToTop onBackToTop={onBackToTopHandler} />


        </article>

        <img src={separator} alt="separator line" className={styles.articleSeparator} />

        <article className='article4'>

          <h3 className={styles.articleTitleCenter}>MEDIA</h3>
              <p className = {styles.kuerze}>Medien zu meiner Tätigkeit als Trainer kommen in Kürze!</p>
          {/* <div className={styles.articleContent} style={{ marginBottom: '40px' }}>
          </div>
          {modalIsOn && ReactDOM.createPortal(<Backdrop isOn={modalIsOn} />, backdrop)}
          {modalIsOn && ReactDOM.createPortal(
            <ImageModal
              itemData={itemData}
              imageInModal={itemData[displayedImageInModal].img}
              imageId={displayedImageInModal}
              howManyImages={itemData.length}
              onModalClose={onClickOnX}
              onArrowClick={imageChangeHandler} />,
            imageViewer)}
          <StandardImageList onImageClick={imageClickHandler} itemData={itemData} />




          <BackToTop onBackToTop={onBackToTopHandler} /> */}


        </article>

        <Footer color={props.viewport > 430 ? 'rgba(208,110,110,1)' : 'transparent'} />

      </section>

      {(props.viewport <= 430 && props.viewportHeight > 430) &&
        <SideMenu
          link1='ABOUT'
          link2='WORKSHOPS'
          link3='KUNDEN'
          link4='MEDIA'
          homeLinkColor='#fff245'
          onLink1Click={onGoToArticle1}
          onLink2Click={onGoToArticle2}
          onLink3Click={onGoToArticle3}
          onLink4Click={onGoToArticle4}
          isActive={activePage}
          viewport={props.viewport}
        />}

    </main>
  );

}

export default Trainer;