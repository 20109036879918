import React, { useState } from "react";

const dataContext = React.createContext(
    {
        events: [{}],
        getEvents: () => {}
    }
);

export const DataContextProvider = (props) => {

    const zeroEvents = {
        id: null,
        titel: 'No events planned',
        link: null,
        untertitel: null,
        ort: null
    }
    
    const [events, setEvents] = useState([zeroEvents]);

    const getEvents = (ev) => {
        setEvents(ev);
    }

    const ctxValue = {
        events,
        getEvents
    }


    return <dataContext.Provider value={ctxValue}>{props.children}</dataContext.Provider>
}


export default dataContext;

