import styles from './login.module.css';
import { useRef, useState } from 'react';
import submitArrow from '../assets/images/back_to_top.svg';
import Header from '../components/Header';
import { useContext } from 'react';
import authContext from '../store/authContext';
import { useNavigate } from 'react-router-dom';



const Login = (props) => {

    const API_URL = process.env.REACT_APP_API_URL;

    const authCtx = useContext(authContext);

    const [responseMessage, setResponseMessage] = useState('')

    const navigate = useNavigate();


    const inputUsernameRef = useRef();
    const inputPasswordRef = useRef();

    const onSubmitHandler = (event) => {
        event.preventDefault();
        const username = inputUsernameRef.current.value;
        const password = inputPasswordRef.current.value;
        const body = JSON.stringify({username, password});
        setResponseMessage('submitting data...')

        fetch(`${API_URL}/api/controller/session.php`, {
            method: 'POST',
            body: body,
            headers: {'Content-Type': 'application/json'}
              })
              .then(response => {
                if (response.ok) 
                   return response.json()
              })
              .then(allData => {
                if(allData.httpStatusCode === 200) {
                  authCtx.login(allData.data.sessionId, allData.data.accesstoken, allData.data.accesstokenexpiryseconds);
                  setResponseMessage('Login successful!')
                setTimeout(() => {navigate('../admin')}, 2000);
                } else {
                    setResponseMessage(allData.messages[0]);
                    setTimeout(() => {navigate('../home')}, 2000);
                }
             }).catch(() => {
                setResponseMessage('Login failed');
                setTimeout(() => {navigate('../home')}, 1500);

            });
             

    }
    return (
        <div className = {styles.container}>
            <Header color = 'black' fontColor='white' position='relative' top= '0' right = '0' width = '200px' />

            <form className = {styles.form} onSubmit={onSubmitHandler}>
                <h1 className={styles.title}>Login</h1>
                <label htmlFor='username' className = {styles.label}>Username</label>
                <input ref={inputUsernameRef} name = 'username' type='text' className = {styles.input} />
                <label htmlFor='password' className = {styles.label}>Passwort</label>
                <input ref={inputPasswordRef} name = 'password' type='password' className = {styles.input} />
                <div className={styles.buttons}>
                <button type="submit" className={styles.button} onClick = {onSubmitHandler}>
                <p>SUBMIT</p>
                <img className={styles.submit} src ={submitArrow} alt='submit' />
                 </button>
                 
                </div>
                <div className={styles.responseMessage}>{responseMessage}</div>

            </form>
            
        </div>
    )
}

export default Login;