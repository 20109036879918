import styles from './homeHeader.module.css';

import Impressum from './Impressum';
import Events from './Events';
import Contact from './Contact';

import CloseIcon from '@mui/icons-material/Close';



const HomeHeader = (props) => {
    return(
        <header className={props.open ? styles.logo_box_open : styles.logo_box}>
            {props.open && <CloseIcon sx = {{width: '50px', height: '50px'}} className = {styles.close} onClick = {props.onModalClose} />}
        <div className={styles.logo}>
        <h1 className={styles.firstName}>Maik</h1>
        <hr className={styles.upperLine}></hr>
        <hr className={styles.lowerLine}></hr>
        <h1 className={styles.name}>Goth</h1>
        </div>
        {(props.open && props.page === 'impressum') && <Impressum />}
        {(props.open && props.page === 'events') && <Events onModalClose ={props.onModalClose}/>}
        {(props.open && props.page === 'contact') && <Contact open = {props.open} onModalClose ={props.onModalClose}/>}
    </header>
    );
}

export default HomeHeader;