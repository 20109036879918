import styles from './backToHome.module.css';
import backToHomeArrow from '../../assets/images/back_to_home.svg';
import {useNavigate} from 'react-router-dom';

const BackToHome = () => {
    let navigate = useNavigate();

    return(
        <div className={styles.container} onClick={() => navigate('../home')}>
            <div className={styles.text}>
                Back to HOME
            </div>
            <img src={backToHomeArrow} className={styles.picture} />

        </div>
    );

}

export default BackToHome;