import { useNavigate } from 'react-router-dom';
import { useState, useReducer, useEffect } from 'react';

import styles from './home.module.css';
import bitmap1 from '../assets/images/bitmap_lyricist.png';
import bitmap2 from '../assets/images/bitmap_philologist.png';
import bitmap3 from '../assets/images/bitmap_musician.png';
import bitmap4 from '../assets/images/bitmap_trainer_retouched.png';
import fb from '../assets/images/f_logo.png';
import insta from '../assets/images/i_logo.png';
import xWhite from '../assets/images/logo-x-white.svg';


import Badge from '../components/Badge';
import HomeHeader from '../components/HomeHeader';
import CrookedSeparator from '../components/UI/CrookedSeparator';



const Home = () => {

    const [disableHover, setDisableHover] = useState(true);

useEffect(() => {
    const timer = setTimeout(() => setDisableHover(false), 500);
    return () => clearTimeout(timer);
}, []);

    const modalReducer = (state, action) => {

        switch(action.type) {

            case 'CLOSE_EVERYTHING':

                return {page:'none', open: false}

            break;

            case 'OPEN_EVENTS':

                return {page: 'events', open: true}

            break;

            case 'OPEN_IMPRESSUM':

                return {page: 'impressum', open: true}

            break;

            case 'OPEN_CONTACT': 

            return {page: 'contact', open: true}
            
            break;

            default: 

            return {page: 'none', open: false}
        }

    
    }

    const onModalCloseHandler = () => {
        dispatchModalState({type: 'CLOSE_EVERYTHING'});
        }

    const eventsOpenHandler = () => {
        dispatchModalState({type: 'OPEN_EVENTS'});
        }

    const impressumOpenHandler = () => {
        dispatchModalState({type: 'OPEN_IMPRESSUM'});
        }

    const contactOpenHandler = () => {
        dispatchModalState({type: 'OPEN_CONTACT'})
    }

    const [modalState, dispatchModalState] = useReducer(modalReducer, {page: 'none', open: false});

    const [upperLeftHover, setUpperLeftHover] = useState(false);
    const [lowerLeftHover, setLowerLeftHover] = useState(false);
    const [upperRightHover, setUpperRightHover] = useState(false);
    const [lowerRightHover, setLowerRightHover] = useState(false);

    let navigate = useNavigate();

    const navigateHandler = (link) => {
        navigate(link);
    }


 return (
    <main className={modalState.open ? styles.container_open : styles.container}>

    <nav id='upperMenuBar' className={modalState.open ? styles.upperMenuBar_open : styles.upperMenuBar}>
        <ul className={styles.upperMenu}>
            <li className={styles.menuItem} onClick={eventsOpenHandler}>Events</li>
            <li className={styles.pallina}></li>
            <li className={styles.menuItem} onClick={impressumOpenHandler}>Impressum</li>
            <li className={styles.pallina}></li>
            <li className={styles.menuItem} onClick={contactOpenHandler}>Kontakt</li>
        </ul>
    </nav>
        
    <div className={disableHover ? styles.noHoverLyricist : (modalState.open ? styles.upperleft_open : styles.upperleft)} 
        onClick={() => navigateHandler('../lyricist')}
        onMouseEnter={() => setUpperLeftHover(true)}
        onMouseLeave={() => setUpperLeftHover(false)}>
            
    <img src={bitmap1} className={modalState.open ? styles.bild_upperleft_open : (!upperLeftHover ? styles.bild_upperleft : styles.bild_upperleft_hover)} alt="lyricist" />
    <div className={modalState.open ? styles.badge_open : styles.badge_lyricist}>
    <h1>Autor</h1>
    <CrookedSeparator width = '80%' left= '10%'/>
    </div>
    </div>

    <div className={disableHover ? styles.noHoverPhilologist : (modalState.open ? styles.upperright_open : styles.upperright)} 
        onClick={() => navigateHandler('../scholar')}
        onMouseEnter={() => setUpperRightHover(true)}
        onMouseLeave={() => setUpperRightHover(false)}>
    <img src={bitmap2} className={modalState.open ? styles.bild_upperright_open :(!upperRightHover ? styles.bild_upperright : styles.bild_upperright_hover)} alt="scholar" />
    <div className={modalState.open ? styles.badge_open : styles.badge_philologist}>
    <h1>Akademiker</h1>
    <CrookedSeparator width = '60%' left= '20%'/>
    </div>
    </div>

   <HomeHeader open={modalState.open} page={modalState.page} onModalClose={onModalCloseHandler}/>

   <div className={disableHover ? styles.noHoverComposer : (modalState.open ? styles.lowerleft_open : styles.lowerleft)} 
        onClick={() => navigateHandler('../musician')}
        onMouseEnter={() => setLowerLeftHover(true)}
        onMouseLeave={() => setLowerLeftHover(false)}>
    <img src={bitmap3} className={modalState.open ? styles.bild_lowerleft_open : (!lowerLeftHover ? styles.bild_lowerleft : styles.bild_lowerleft_hover)} alt="musician" />
    <div className={modalState.open ? styles.badge_open : styles.badge_composer}>
    <h1>Musiker</h1>
    <CrookedSeparator width = '70%' left= '15%'/>
    </div>
    </div>

    <div className={disableHover ? styles.noHoverGuitarist : (modalState.open ? styles.lowerright_open : styles.lowerright)} 
        onClick={() => navigateHandler('../trainer')}
        onMouseEnter={() => setLowerRightHover(true)}
        onMouseLeave={() => setLowerRightHover(false)}>
    <img src={bitmap4} className={modalState.open ? styles.bild_lowerright_open :(!lowerRightHover ? styles.bild_lowerright : styles.bild_lowerright_hover)} alt="trainer" />
    <div className={modalState.open ? styles.badge_open : styles.badge_guitarist}>
    <h1>Trainer</h1>
    <CrookedSeparator width = '80%' left= '10%'/>
    </div>

    </div>

    <div className={modalState.open ? styles. lowerMenuBar_open : styles.lowerMenuBar}>
{/*     <img src={fb} className={styles.fbLogo} alt="facebook logo" /> */}
    <a className={styles.inLink} href="https://www.instagram.com/maikgoth/"> 
   <img src={insta} className={styles.inLogo} alt="instagram logo" />
   </a>
   <a className={styles.xLink} href="https://x.com/GothMaik"> 
    <img src={xWhite} className={styles.xLogo} alt="X logo" />
    </a>


    </div>
    </main>
 );
}

export default Home;