import styles from './singleEvent.module.css';
import CrookedSeparator from './UI/CrookedSeparator';

const SingleEvent = (props) => {

    
        if (props.link != null) {
         return (
             <div className={styles.events_content}>
         <h5 className={styles.datum}>{props.datum}</h5>
         <h4 className={styles.titel}>{props.titel}</h4>
         <p className={styles.untertitel}>{props.untertitel}</p>
         <p className={styles.ort}>{props.ort}</p>
         <p className={styles.link}><a href={props.link}>Zum Event &rarr;</a></p>
         <CrookedSeparator color='white' width='60%' left='20%'/>
         </div>
         )
        } else  { 
             return (
         <div className={styles.events_content}>
         <h5 className={styles.datum}>{props.datum}</h5>
         <h4 className={styles.titel}>{props.titel}</h4>
         <p className={styles.untertitel}>{props.untertitel}</p>
         <p className={styles.ort}>{props.ort}</p>
         <CrookedSeparator color='white' width='60%' left='20%'/>

         </div>
         )
        }
    }

export default SingleEvent;