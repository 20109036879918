import { useState} from 'react';

import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import fullscreen from '../../assets/images/fullscreen.svg';
import smallscreen from '../../assets/images/smallscreen.svg';
import LoadingSpinner from './LoadingSpinner';

import styles from './imageModal.module.css';


const ImageModal = (props) => {

    

    const [imageIsLoaded, setImageIsLoaded] = useState(false);
    const [imageBrightness, setImageBrightness] = useState('');
    const [isFullScreen, setIsFullScreen] = useState(false);

    const oneImageBack = () => {
        setImageBrightness('gray');
        setImageIsLoaded(false);
        if(props.imageId === 0) props.onArrowClick((props.howManyImages)-1);
        else props.onArrowClick((props.imageId)-1);
    }

    const oneImageForward = () => {
        setImageBrightness('gray');
        setImageIsLoaded(false);
        if(props.imageId >= ((props.howManyImages)-1)) props.onArrowClick(0);
        else props.onArrowClick((props.imageId)+1);
    }

    document.addEventListener('keydown', (event) => {
        if(event.key === 'ArrowLeft') oneImageBack();
        if(event.key === 'ArrowRight') oneImageForward();
        else return;
    });

    const onLoadHandler = () => {
        setImageIsLoaded(true);
        setImageBrightness('');
    }

    const onToggleFullScreenHandler = () => {
        setIsFullScreen(!isFullScreen);
    }


    return (
        <div className={isFullScreen ? styles.containerFullScreen : styles.container}>
            <CloseIcon sx = {{width: '30px', height: '30px'}} className = {styles.close} onClick = {props.onModalClose}/>
            {isFullScreen ? 
            <img src={smallscreen} className={styles.fullscreen} alt='toggle fullscreen' onClick = {onToggleFullScreenHandler} /> :
            <img src={fullscreen} className={styles.fullscreen} alt='toggle fullscreen' onClick = {onToggleFullScreenHandler}/>}

            <div className={isFullScreen ? styles.modalFullScreen : styles.modal}>
                {!isFullScreen && <div className={styles.arrowBox} onClick = {oneImageBack}>
                        <ArrowBackIosIcon className={styles.arrow} /></div>}
             {!isFullScreen ? 
             <img className={imageBrightness === 'gray' ? styles.blackAndWhiteImg : styles.image} src={props.imageInModal} onLoad={onLoadHandler}/>
             : <img className={styles.imageFullScreen} src={props.imageInModal} onLoad={onLoadHandler}/> }
               {!imageIsLoaded && <LoadingSpinner />}
                {!isFullScreen &&
                <>
               
                <div className={styles.arrowBox} onClick = {oneImageForward}>
                 
                <ArrowForwardIosIcon className={styles.arrow}/></div>
                </>
                }
            </div>

        </div>
    )
}

export default ImageModal;