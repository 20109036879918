import styles from './admin.module.css';
import authContext from '../store/authContext';
import Header from '../components/Header';
import AddEventForm from '../components/admin/AddEventForm';
import DeleteEventForm from '../components/admin/DeleteEventForm';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';

const Admin = () => {

    const API_URL = process.env.REACT_APP_API_URL;

    const authCtx = useContext(authContext);
    const navigate = useNavigate();

    const [adminForm, setAdminForm] = useState(null);
    const [loggingOut, setLoggingOut] = useState(false);

    const loginCheck = () => {

        if (localStorage['sessionData']) {
          
          const decodedStorage = JSON.parse(localStorage.getItem('sessionData'));
          const date = new Date();
          const fetchedId = decodedStorage.id;
          const fetchedAccessToken = decodedStorage.token;
          const fetchedExpiry= date.getTime();
          
      fetch(`${API_URL}/api/controller/session.php?sessionid=${fetchedId}&expires=${fetchedExpiry}`, {
          method: 'PATCH',
              headers: {
                  'Authorization': fetchedAccessToken
              }
            })
            .then(response => {
              if (response.ok) {
                 return response.json()}
                 else {
                  throw new Error('Error checking login status');
                }})
            .then(allData => {
              if(allData.messages[0] !== 'Token successfully checked') {
                authCtx.logout();
                navigate('../login');
              }
             }).catch(error => console.log(error));
            } else navigate('../login');
        }

      useEffect(() => {
        loginCheck();
    }, []);


    const onLogoutHandler = () => {

    const decodedStorage = JSON.parse(localStorage.getItem('sessionData'));
    console.log(localStorage);
      const date = new Date();
      const fetchedId = decodedStorage.id;
      const fetchedAccessToken = decodedStorage.token;
      const fetchedExpiry= date.getTime();

         fetch(`${API_URL}/api/controller/session.php?sessionid=${fetchedId}&expires=${fetchedExpiry}`, {
            method: 'DELETE',
            headers: {
                'Authorization': fetchedAccessToken
                    }
                })
            .then(response => {if(response.ok) { return response.json() }})
            .then(allData => {
                if(allData.messages[0] === 'User successfully logged out') {
                    setLoggingOut(true);
                    setTimeout( ()=>{ authCtx.logout(); navigate('../login')}, 2000 );
                } else return;
            })
        }

    const onAddFormCallHandler = () => {
        setAdminForm('add');
    }

    const onDeleteFormCallHandler = () => {
        setAdminForm('delete');
    }
    
    
    return (
        <main className={styles.container}>
            
            <section className={adminForm ? styles.linksOpen : styles.links}>
            <Header color='white' fontColor='black' position='static' alignItems='flex-start' justifyContent='flex-start' width='auto'/>

        <button className={styles.link} onClick = { onAddFormCallHandler }>Add an Event</button>
        <button className={styles.link} onClick = { onDeleteFormCallHandler}>Delete an Event</button>
        
        {loggingOut ? <h2 className={styles.link}>Logging out...</h2> : <button className={styles.link} onClick = { onLogoutHandler }>LOGOUT</button>}
            </section>

            <section className={styles.form}>
            {adminForm === 'add' && <AddEventForm />}
            {adminForm === 'delete' && <DeleteEventForm />}
            </section>

        </main>

    );

}

export default Admin;