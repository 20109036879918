import * as React from 'react';


import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import styles from './standardImageList.module.css';

export default function StandardImageList(props) {

    const itemData = props.itemData;

    const onMouseOverHandler = () => {
        console.log('hovering');
    }

    const onClickHandler = (imageId) => {
      props.onImageClick(imageId);
  }

  return (

        <ImageList sx={{ width: '100%', height: 1000, margin: '20px auto', overflow: 'scroll', scrollbarWidth: 'none'} } cols={3} rowHeight={'auto'} gap={10}>
          {itemData.map((item, id) => (
            <ImageListItem key={item.img} onClick = {() => {onClickHandler(id)}} onMouseOver = {onMouseOverHandler}>
              <img
                src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy" 
              />
            </ImageListItem>
          ))}
        </ImageList>

  );
}

