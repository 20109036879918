import styles from './deleteEventForm.module.css';
import dataContext from '../../store/dataContext';
import { useContext, useEffect, useState, Fragment } from 'react';
import SingleEvent from '../SingleEvent';

const DeleteEventForm = (props) => {

    const API_URL = process.env.REACT_APP_API_URL;

    const dataCtx = useContext(dataContext);
    const [responseMessage, setResponseMessage] = useState('');

    const getEvents = async () => { 
                
        const response = await fetch(`${API_URL}/api/controller/eventTasks.php`, {
        method: 'GET'
          });
        const allData = await response.json();
        
        if(allData.messages[0] === 'Events successfully retrieved') {
              dataCtx.getEvents(allData.data);
            }
            else if(allData.messages[0] === 'No events found') {
                dataCtx.getEvents([{id: null, titel: 'No events planned yet', link: null, untertitel: 'Stay tuned!', ort: null}]);
            } else alert('Error retrieving events');
        };

    useEffect(
                () => {
                getEvents();
                }, []
            );


            const onConcertDeleteHandler = (id) => {
                setResponseMessage('');
                const body = "event_id=" + id;
                fetch (`${API_URL}/api/controller/eventTasks.php?${body}`,
                {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                })
                .then(response => {
                    if(response.ok) return response.json();
                    else throw new Error(response.messages[0])})
                .then(allData => {setResponseMessage(allData.messages[0]); getEvents()})
                .catch(error => setResponseMessage(error))
                    
            }

        const eventsDisplay = dataCtx.events.map(event => 
                                    <div className={styles.singleEvent}>
                                    <SingleEvent 
                                    key={Math.random()}
                                    datum={event.datum}
                                    link={event.link}
                                    titel = {event.titel}
                                    untertitel = {event.untertitel}
                                    ort = {event.ort}/>
                                    <button key = {event.id} className={styles.button} onClick = {() => {onConcertDeleteHandler(event.id)}}>DELETE EVENT</button>
                                    </div>)

    return(
        <div className = {styles.formContainer}>
            <h3 className={styles.title}>DELETE EVENTS</h3>
            {responseMessage !== '' && <p className={styles.responseMessage}>{responseMessage}</p>}

            <div className={styles.events_list}>

            {eventsDisplay}

        </div>
      </div>
    );
}

export default DeleteEventForm;