import styles from './footer.module.css';
import { Link } from 'react-router-dom';
import BackToHome from './UI/BackToHome';



const Footer = (props) => {


    return(
    <div className={styles.banner} style={{backgroundColor: props.color}}>
        <div className={styles.logo_box}>
            <h1 className={styles.firstName}>Maik</h1>
            <hr className={styles.upperLine} />
            <hr className={styles.lowerLine} />
            <h1 className={styles.name}>Goth</h1>
        </div>
        <nav>
            <div className={styles.navFirstLine}>
                <Link to='../lyricist'>Autor</Link>
                <Link to='../scholar'>Akademiker</Link>

            </div>
            <div className={styles.navSecondLine}>
            <Link to='../musician'>Musiker</Link>
                <Link to='../guitarist'>Trainer</Link>
            </div>
        </nav>
        <BackToHome />
    </div>
    );
}

export default Footer;