import styles from './crookedSeparator.module.css';
import { Fragment } from 'react';

const CrookedSeparator = (props) => {
    return (
        <Fragment>
       <hr className={styles.upperLine} style={{backgroundColor : props.color, width: props.width, left: props.left, top: props.top}}/>
       <hr className={styles.lowerLine} style={{backgroundColor : props.color, width: props.width, left: props.left, top: props.top}}/>
       </Fragment>
    )
}

export default CrookedSeparator;