import styles from './header.module.css';
import CrookedSeparator from './UI/CrookedSeparator';

const Header = (props) => {
    return (
        <div className={styles.banner} style={{backgroundColor: props.color, 
                                                position: props.position,
                                                top: props.top,
                                                left: props.left,
                                                right: props.right,
                                                justifyContent: props.justifyContent,
                                                alignItems: props.alignItems,
                                                width: props.width
                                                }}>
            <div className={styles.title}>
            <h1 className={styles.firstName} style={{color: props.fontColor}}>Maik</h1>
            <h1 className={styles.lastName} style={{color: props.fontColor}}>Goth</h1>
            
            </div>
            <CrookedSeparator 
            color = {props.viewport > 430 ? 'black' : 'white'}
            left = {props.viewportHeight <= 430 && '-120px' }/>
            <h2 className={styles.profession}>{props.children}</h2>



        </div>
    );
}

export default Header;