import styles from './sidemenu.module.css';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import decorator from '../assets/images/sidemenu_decorator.png';
import downwards_decorator from '../assets/images/sidemenu_decorator_downwards.png';

import CrookedSeparator from './UI/CrookedSeparator';


const SideMenu = (props) => {

    const [isHovered, setIsHovered] = useState();

    const navigate = useNavigate();

    const onMenuItemEnterHandler = (link) => {
        if (link !== props.isActive) {
            setIsHovered(link);
        } else return;
    }
    
    const onMenuItemLeaveHandler = (link) => {
        if (link !== props.isActive) {
            setIsHovered('');
        } else return;
    }

    const activeLink1ClickHandler = () => {
        props.onLink1Click();
        setIsHovered('');
    }

    const activeLink2ClickHandler = () => {
        props.onLink2Click();
        setIsHovered('');
    }

    const activeLink3ClickHandler = () => {
        props.onLink3Click();
        setIsHovered('');
    }

    const activeLink4ClickHandler = () => {
        props.onLink4Click();
        setIsHovered('');
    }

    return(
    <aside className={styles.container}>

        {(props.viewport>430 && props.viewportHeight > 430) &&
            <div className={styles.decorator_left}>
                <img src={decorator} alt = 'menu decorator' />
            </div>}

        <nav className={styles.sideMenuBox}>
            <ul className={styles.sideMenu}>
                <li className={styles.sideMenuItem}
                key = {1}
                style = {props.homeLinkColor && {color: props.homeLinkColor}}
                onMouseEnter ={() => {onMenuItemEnterHandler('link1')}} 
                onMouseLeave={() => {onMenuItemLeaveHandler('link1')}}
                onClick = {() => { navigate('../home')}}>
                    Home
                    </li>
                <li className={styles.activeLink} >
                    {(((props.viewport > 430 && props.viewportHeight > 430) && isHovered === 'link1') || props.isActive === 'link1') && 
                         <CrookedSeparator top = {props.viewport>= 540 && props.viewport<= 896 ? '0' : '-12px' }
                         left = {(props.viewport > 430 || props.viewportHeight > 430) ? '0' : '12px'} 
                         color={props.viewport > 430 ? 'black' : 'white'} 
                         width = {props.viewport > 430 ? '60%' : '80%'}/> }</li>
                <li className={styles.sideMenuItem} 
                key = {2} 
                onMouseEnter ={() => {onMenuItemEnterHandler('link2')}} 
                onMouseLeave={() =>{onMenuItemLeaveHandler('link2')}} 
                onClick={activeLink1ClickHandler}>
                    {props.link1}
                    </li>
                <li className=
                    {styles.activeLink}>{(((props.viewport > 430 && props.viewportHeight > 430) && isHovered === 'link2') || props.isActive === 'link2') && 
                    <CrookedSeparator top = {props.viewport>= 540 && props.viewport<= 896 ? '0' : '-12px' }
                                    left = {(props.viewport > 430 || props.viewportHeight > 430) ? '0' : '12px'} 
                    color={props.viewport > 430 ? 'black' : 'white'} 
                    width = '60%'/>  }</li>
                <li className={styles.sideMenuItem} 
                key = {3} 
                onMouseEnter ={() => {onMenuItemEnterHandler('link3')}} 
                onMouseLeave={() =>{onMenuItemLeaveHandler('link3')}} 
                onClick={activeLink2ClickHandler}>
                    {props.link2}
                    </li>
                <li className={styles.activeLink}>{(((props.viewport > 430 && props.viewportHeight > 430) && isHovered === 'link3') || props.isActive === 'link3') && 
                     <CrookedSeparator top = {props.viewport>= 540 && props.viewport<= 896 ? '0' : '-12px' }
                     left = {(props.viewport > 430 || props.viewportHeight > 430) ? '0' : '12px'} 
                     color={props.viewport > 430 ? 'black' : 'white'} 
                     width = '60%'/> }</li>
                <li className={styles.sideMenuItem} 
                key = {4} 
                onMouseEnter ={() => {onMenuItemEnterHandler('link4')}} 
                onMouseLeave={() =>{onMenuItemLeaveHandler('link4')}}
                onClick={activeLink3ClickHandler}>
                    {props.link3}
                    </li>
                <li className={styles.activeLink}>{(((props.viewport > 430 && props.viewportHeight > 430) && isHovered === 'link4') || props.isActive === 'link4') &&  
                                <CrookedSeparator top = {props.viewport>= 540 && props.viewport<= 896 ? '0' : '-12px' }
                                left = {(props.viewport > 430 || props.viewportHeight > 430) ? '0' : '12px'}

                                    color={props.viewport > 430 ? 'black' : 'white'} 
                                    width = '60%'/> }</li>
                <li className={styles.sideMenuItem} 
                onMouseEnter ={() => {onMenuItemEnterHandler('link5')}} 
                onMouseLeave={() => {onMenuItemLeaveHandler('link5')}}
                onClick={activeLink4ClickHandler}>
                    {props.link4}
                    </li>
                <li className={styles.activeLink}>{((((props.viewport > 820 && props.viewportHeight > 430) ) && isHovered === 'link5') || props.isActive === 'link5') && 
                    <CrookedSeparator top = {props.viewport>= 540 && props.viewport<= 896 ? '0' : '-12px' }
                                    left = {(props.viewport > 430 || props.viewportHeight > 430)  ? '0' : '12px'} 
                                    color={props.viewport > 430 ? 'black' : 'white'} 
                                    width = '60%'/> }</li>
                
            </ul>
        </nav>

    </aside>

    );
}

export default SideMenu;