import styles from './addEventForm.module.css';

import backArrow from '../../assets/images/back_white.svg';
import resetCross from '../../assets/images/reset_white.svg';
import authContext from '../../store/authContext';

import { useRef, useState, useContext } from 'react';

const AddEventForm = (props) => {

    const API_URL = process.env.REACT_APP_API_URL;

    const authCtx = useContext(authContext);

    const form = document.querySelector('form');

    const [responseMessage, setResponseMessage] = useState('');

    const dateRef = useRef();
    const titleRef = useRef();
    const linkRef = useRef();
    const descriptionRef = useRef();
    const placeRef = useRef();


    // Checks if logged in

    const loginCheck = () => {

    if (localStorage['sessionData']) {
      
      const decodedStorage = JSON.parse(localStorage.getItem('sessionData'));
      const date = new Date();
      const fetchedId = decodedStorage.id;
      const fetchedAccessToken = decodedStorage.token;
      const fetchedExpiry= date.getTime();
      
  fetch(`${API_URL}/api/controller/session.php?sessionid=${fetchedId}&expires=${fetchedExpiry}`, {
      method: 'PATCH',
          headers: {
              'Authorization': fetchedAccessToken
          }
        })
        .then(response => {
          if (response.ok) {
             return response.json()}
             else {
              throw new Error('Error checking login status');
            }})
        .then(allData => {
          if(allData.messages[0] !== 'Token successfully checked') {
            setResponseMessage(allData.messages[0]);
            setTimeout(() => {authCtx.logout()}, 2000);
            
          }
         }).catch(error => console.log(error));
        } else return;
    }

    // Submits the form, checks if fields are empty
    const onFormSubmitHandler = (event) => {
        event.preventDefault();
        loginCheck();
        setResponseMessage('waiting...');

        const dateInput = dateRef.current.value;
        const titleInput = titleRef.current.value;
        const linkInput = linkRef.current.value;
        const descriptionInput = descriptionRef.current.value;
        const placeInput = placeRef.current.value;

        if ( dateInput ==='' || titleInput === '') {
            setResponseMessage('Required fields cannot be empty');
            return;
        }

        const eventObject = {datum: dateInput, titel: titleInput, link: linkInput, untertitel: descriptionInput, ort: placeInput};
        const eventJSON = JSON.stringify(eventObject);

      fetch(`${API_URL}/api/controller/eventTasks.php`, {
            method: 'POST',
            body: eventJSON,
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(response => {
            if(response.ok) return response.json()
            else throw new Error('Server error, please try again');})
        .then(responseData => {
            if(responseData) setResponseMessage(responseData.messages[0]);
            })
        .catch(error =>setResponseMessage(error));
    }


    const onFormResetHandler = () => {
        form.reset();
}
    return (
        <form className={styles.contactForm}>
            
            <div className={styles.title}>Add an Event</div>

            <div className={styles.inputField}>
            <label htmlFor='datum' className={styles.label}>Date (required)</label>
            <input ref={dateRef} name='datum' type='date' className={styles.input} required ></input>
            </div>
            <div className={styles.inputField}>
            <label htmlFor='' className={styles.label}>Title (required)</label>
            <input ref={titleRef} name='titel' type='text' className={styles.input} ></input>
            </div>
            <div className={styles.inputField}>
            <label htmlFor='link' className={styles.label}>Link</label>
            <input ref={linkRef}name='link' type='text' className={styles.input}></input>
            </div>
            <div className={styles.inputField}>
            <label htmlFor='untertitel' className={styles.label}>Description</label>
            <input ref={descriptionRef} name='untertitel' type='text' className={styles.input}></input>
            </div>
            <div className={styles.inputField}>
            <label htmlFor='ort' className={styles.label}>Place</label>
            <input ref={placeRef} name='ort' type='text' className={styles.input}></input>
            </div>

            <aside className={styles.buttons}>
            <button type="reset" onClick={onFormResetHandler} className={styles.button}>
            <p>RESET</p>
            <img src ={resetCross} alt='reset' />
            </button>
            <button type="submit" onClick={onFormSubmitHandler} className={styles.button}>
            <p>SUBMIT</p>
            <img className={styles.submit} src ={backArrow} alt='submit' />
            </button>
            </aside>
            <div className={responseMessage !== '' ? styles.formResponseMessage : styles.hidden}>{responseMessage}</div>
        
        </form>
    );
}

export default AddEventForm;