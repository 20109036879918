import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import {useState } from 'react';
import authContext from "./store/authContext";
import Home from './pages/Home';
import Lyricist from './pages/Lyricist';
import Scholar from './pages/Scholar';
import Trainer from './pages/Trainer';
import Musician from './pages/Musician';
import Login from './pages/Login';
import Admin from './pages/Admin';


function App() {

  const viewportHeight = window.innerHeight;
  // speichert der Viewport-Breite in der Variable "viewport"
  const [viewport, setViewport] = useState(window.innerWidth);

  // speichert der Orientation in der Variable "orientation"
  const [orientation, setOrientation] = useState(
    window.screen.orientation.angle
  );

  // aktualisiert die Variable "viewport" falls die Fenstergröße geändert wird
  window.addEventListener("resize", () => {
    setViewport(window.innerWidth);
  });

  // aktualisiert die Variablen "viewport" und "orientation" falls die Mobile Device Orientation geändert wird
  window.screen.orientation.addEventListener("change", (event) => {
    if (event.target.angle !== 0) {
      setViewport(window.innerWidth);
      setOrientation(window.screen.orientation.angle);
    }
  });

  // falls die Orientation sich ändert (bei Mobile Devices), dann wird sie auf "Portrait" fixiert
  if (viewport <= 820 && orientation !== 0) {
    window.screen.orientation.lock("portrait").catch((error) => {
      console.error(error);
    });
  }


  return (
    <div className="App">
      <Routes>
        <Route path = '/' element = {<Navigate to='/home' />} />
        <Route path = '/home' element = {<Home />} />
        <Route path = 'lyricist' element = {<Lyricist viewport={viewport} viewportHeight = {viewportHeight}/>} />
        <Route path = 'scholar' element = {<Scholar viewport={viewport} viewportHeight = {viewportHeight}/>} />
        <Route path = 'musician' element = {<Musician viewport={viewport} viewportHeight = {viewportHeight}/>} />
        <Route path = 'trainer' element = {<Trainer viewport={viewport} viewportHeight = {viewportHeight}/>} />
        <Route path = 'login' element = {<Login />} />
        <Route path = 'admin' element = {<Admin />} />
        </Routes>
    </div>
  );
}

export default App;
